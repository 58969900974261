import ApiClient from "@/api/ApiClient";
import {
  ShiftPatternListResponse,
  ShiftPatternMonthlyResponse,
  ShiftPatternResponse,
  ShiftPatternSettingListResponse,
  ShiftPatternSettingResponse,
  ShiftUsersResponse,
  WorkDescriptionSettingListResponse,
  WorkDescriptionSettingResponse,
} from "chaild-api/lib";
import { ShiftTimetableCategoryType } from "chaild-api/src/constant/shiftTimetableCategory";

export interface ShiftTimeTableRequest {
  name: string | null;
  abbreviation: string | null;
  color: string | null;
  startMin: number;
  endMin: number;
  comment: string | null;
  workDescriptionId?: number;
  category: string | ShiftTimetableCategoryType;
}

export interface ShiftPatternUpdateRequest {
  shiftPatternId: number;
  name: string;
  abbreviation: string;
  color: string | null;
  timetables: ShiftTimeTableRequest[];
}

export interface DayScheduleUpdateRequestTimetable {
  operationType: "add" | "update" | "remove";
  timetableId?: number;
  name: string;
  abbreviation: string;
  color: string | null;
  startMin: number;
  endMin: number;
  comment: string | null;
  workDescriptionId?: number;
  category: ShiftTimetableCategoryType;
}

export interface DayScheduleUpdateRequest {
  name: string;
  abbreviation: string;
  color: string | null;
  timetables: DayScheduleUpdateRequestTimetable[];
  users: [];
}

export default class ApiShift {
  // シフトパターン
  public static async listShiftPattern(nurseryId: number) {
    try {
      const response = await ApiClient.get(`/shift-pattern/setting`, {
        nurseryId,
      });
      return response as ShiftPatternSettingListResponse;
    } catch {
      return null;
    }
  }

  // 作業内容順番更新
  public static async sortShiftPattern({
    nurseryId,
    shiftPatternIds,
  }: {
    nurseryId: number;
    shiftPatternIds: number[];
  }) {
    try {
      const response = await ApiClient.put(`/shift-pattern/setting`, {
        nurseryId,
        shiftPatternIds,
      });
      return response as ShiftPatternSettingListResponse;
    } catch {
      return null;
    }
  }

  public static async createShiftPattern({
    nurseryId,
    name,
    abbreviation,
    color,
    timetables,
  }: {
    nurseryId: number;
    name: string;
    abbreviation: string;
    color: string;
    timetables: ShiftTimeTableRequest[];
  }) {
    try {
      const response = await ApiClient.post(`/shift-pattern/setting`, {
        nurseryId,
        name,
        abbreviation,
        color,
        timetables,
      });
      return response as ShiftPatternSettingResponse;
    } catch {
      return null;
    }
  }

  public static async updateShiftPattern({
    shiftPatternId,
    name,
    abbreviation,
    color,
    timetables,
  }: {
    shiftPatternId: number;
    name: string;
    abbreviation: string;
    color: string | null;
    timetables: ShiftTimeTableRequest[];
  }) {
    try {
      const response = await ApiClient.put(
        `/shift-pattern/setting/${shiftPatternId}`,
        {
          name,
          abbreviation,
          color,
          timetables,
        }
      );
      return response as ShiftPatternSettingResponse;
    } catch {
      return null;
    }
  }

  public static async deleteShiftPattern(id: number) {
    try {
      const response = await ApiClient.delete(
        `/shift-pattern/setting/${id}`,
        {}
      );
      return response;
    } catch {
      return null;
    }
  }

  // 作業内容一覧
  public static async listWorkDescriptions(nurseryId: number) {
    try {
      const response = await ApiClient.get(`/work-description/setting`, {
        nurseryId,
      });
      return response as WorkDescriptionSettingListResponse;
    } catch {
      return null;
    }
  }

  // 作業内容順番更新
  public static async sortWorkDescriptions({
    nurseryId,
    workDescriptionIds,
  }: {
    nurseryId: number;
    workDescriptionIds: number[];
  }) {
    try {
      const response = await ApiClient.put(`/work-description/setting`, {
        nurseryId,
        workDescriptionIds,
      });
      return response as WorkDescriptionSettingListResponse;
    } catch {
      return null;
    }
  }

  public static async createWorkDescription({
    nurseryId,
    name,
    abbreviation,
    color,
    isChildcare,
  }: {
    nurseryId: number;
    name: string;
    abbreviation: string;
    color: string;
    isChildcare: boolean;
  }) {
    try {
      const response = await ApiClient.post(`/work-description/setting`, {
        nurseryId,
        name,
        abbreviation,
        color,
        isChildcare,
      });
      return response as WorkDescriptionSettingResponse;
    } catch {
      return null;
    }
  }

  public static async updateWorkDescription({
    workDescriptionId,
    name,
    abbreviation,
    color,
    isChildcare,
  }: {
    workDescriptionId: number;
    name: string;
    abbreviation: string;
    color: string | null;
    isChildcare: boolean;
  }) {
    try {
      const response = await ApiClient.put(
        `/work-description/setting/${workDescriptionId}`,
        {
          name,
          abbreviation,
          color,
          isChildcare,
        }
      );
      return response as WorkDescriptionSettingResponse;
    } catch {
      return null;
    }
  }

  public static async deleteWorkDescription(id: number) {
    try {
      const response = await ApiClient.delete(
        `/work-description/setting/${id}`,
        {}
      );
      return response;
    } catch {
      return null;
    }
  }

  // 月間シフト
  public static async listShiftUsers({
    nurseryId,
  }: {
    nurseryId: number;
  }): Promise<ShiftUsersResponse | undefined> {
    try {
      return ApiClient.get(`/shift-pattern/users`, {
        nurseryId,
      });
    } catch (e) {
      console.error(e);
    }
  }

  public static async sortShiftUsers({
    nurseryId,
    userIds,
  }: {
    nurseryId: number;
    userIds: number[];
  }): Promise<ShiftUsersResponse | undefined> {
    try {
      return ApiClient.post(`/shift-pattern/users`, {
        nurseryId,
        userIds,
      });
    } catch (e) {
      console.error(e);
    }
  }

  public static async listMonthlySchedule({
    nurseryId,
    year,
    month,
  }: {
    nurseryId: number;
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiClient.get(`/shift-pattern/${year}/${month}`, {
        nurseryId,
      });
      return response as ShiftPatternMonthlyResponse;
    } catch {
      return null;
    }
  }

  public static async registerUsersToMonthlySchedule({
    userIds,
    year,
    month,
  }: {
    userIds: number[];
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiClient.post(`/shift-pattern/${year}/${month}`, {
        userIds,
      });
      return response as ShiftPatternMonthlyResponse;
    } catch {
      return null;
    }
  }

  public static async removeUsersFromMonthlySchedule({
    userIds,
    year,
    month,
  }: {
    userIds: number[];
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiClient.delete(
        `/shift-pattern/${year}/${month}`,
        { userIds: userIds.join(",") }
      );
      return response as ShiftPatternMonthlyResponse;
    } catch {
      return null;
    }
  }

  // パターン適応
  public static async applyMonthlyPattern({
    shiftPatternId,
    userId,
    days,
    year,
    month,
  }: {
    shiftPatternId: number;
    userId: number;
    days: number[];
    year: string | number;
    month: string | number;
  }) {
    try {
      const response = await ApiClient.post(
        `/shift-pattern/${year}/${month}/setting/${shiftPatternId}/copy`,
        {
          userId,
          days,
        }
      );
      return response as ShiftPatternMonthlyResponse;
    } catch {
      return null;
    }
  }

  // 日パターン
  public static async getUserShiftPatternByDate({
    userId,
    year,
    month,
    day,
  }: {
    userId: number;
    year: string | number;
    month: string | number;
    day: string | number;
  }) {
    try {
      const response = await ApiClient.get(
        `/shift-pattern/${year}/${month}/${day}/${userId}`,
        null
      );
      return response as ShiftPatternResponse;
    } catch {
      return null;
    }
  }

  public static async updateUserShiftPatternByDate({
    userId,
    year,
    month,
    day,
    patternRequest,
  }: {
    userId: number;
    year: string | number;
    month: string | number;
    day: string | number;
    patternRequest: DayScheduleUpdateRequest;
  }) {
    try {
      const response = await ApiClient.put(
        `/shift-pattern/${year}/${month}/${day}/${userId}`,
        patternRequest
      );
      return response as ShiftPatternResponse;
    } catch {
      return null;
    }
  }

  public static async deleteUserShiftPatternByDate({
    userId,
    year,
    month,
    day,
  }: {
    userId: number;
    year: string | number;
    month: string | number;
    day: string | number;
  }) {
    try {
      const response = await ApiClient.delete(
        `/shift-pattern/${year}/${month}/${day}/${userId}`,
        null
      );
      return response as ShiftPatternResponse;
    } catch {
      return null;
    }
  }

  // 月間シフト
  public static async getDailySchedule({
    nurseryId,
    year,
    month,
    day,
  }: {
    nurseryId: number;
    year: string | number;
    month: string | number;
    day: string | number;
  }) {
    try {
      const response = await ApiClient.get(
        `/shift-pattern/${year}/${month}/${day}`,
        { nurseryId }
      );
      return response as ShiftPatternListResponse;
    } catch {
      return null;
    }
  }

  /**
   * 月次スタッフ予定表のPDF出力
   */
  static async dlMonthlyShiftPattern(input: {
    month: number;
    year: number;
    nurseryId: number;
  }) {
    return await ApiClient.post(
      `/shift-pattern/${input.year}/${input.month}/pdf`,
      input
    );
  }

  /**
   * 日次スタッフ予定表のPDF出力
   */
  static async dlDailyShiftPattern(input: {
    month: number;
    year: number;
    nurseryId: number;
    day: number;
  }) {
    return await ApiClient.post(
      `/shift-pattern/${input.year}/${input.month}/${input.day}/pdf`,
      input
    );
  }
}
